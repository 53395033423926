/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_qwHDrJO9r",
    "aws_user_pools_web_client_id": "8nv24a4fd0rtbho56r0tksd0j",
    "oauth": {
        "domain": "shared-table-prod-prod.auth.us-west-2.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "http://localhost:3000,http://localhost:3000/home,https://master.d11be6789y4p3f.amplifyapp.com/,https://master.d11be6789y4p3f.amplifyapp.com/home,https://sharedtable.usfoods.com/,https://www.sharedtable.usfoods.com/",
        "redirectSignOut": "http://localhost:3000,http://localhost:3000/signout,https://master.d11be6789y4p3f.amplifyapp.com/,https://master.d11be6789y4p3f.amplifyapp.com/signout,https://sharedtable.usfoods.com/,https://sharedtable.usfoods.com/signout,https://www.sharedtable.usfoods.com/,https://www.sharedtable.usfoods.com/signout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "FAMILY_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": []
};


export default awsmobile;
